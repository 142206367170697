<template>
  <div class="wrap">
    <div class="banner" :style="{backgroundImage: 'url(' + imgUrl.banner + ')'}">
      <div class="text animated fadeIn">
        <p class="icon"></p>
        <p class="h2">病区医嘱执行管理中心与EMR系统</p>
      </div>
    </div>
    <div class="content">
      <div class="en t2"></div>
      <div class="inner product">
        <h2>产品<em>介绍</em></h2>
        <div class="details">
          <div class="img">
            <img src="../../assets/images/emr-text.png"
                 alt="">
          </div>
          <div class="text bg">
            <p>病区医嘱执行管理中心主要针对护理部门实现医嘱数字化与智能化执行，以及自动执行与反德提高护理工作效率,减低人为失误慨率，降低医护人员工作强度，每班次护理人员均配各个人专属医嘱执行电脑,每病床床旁均配备床旁医嘱执行与反馈电脑，医护人员可随时随地掌握医嘱执行与护理执行情况。</p>
          </div>
        </div>
      </div>
    </div>
    <div class="content bg">
      <div class="en t3"></div>
      <div class="inner product">
        <h2><em>服务</em>与<em>功能</em></h2>
        <div class="p-list">
          <ul>
            <li class="p1">
              <em></em>
              <h3>医嘱执行系统</h3>
            </li>
            <li class="p2">
              <em></em>
              <h3>输液执行系统</h3>
            </li>
            <li class="p3">
              <em></em>
              <h3>处置执行系统</h3>
            </li>
            <li class="p4">
              <em></em>
              <h3>交接班工作跟进系统</h3>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getAliyunImg from '../../utils/aliyun'

export default {
  name: 'emr',
  created () {
    this.imgUrl.banner = getAliyunImg.signatureUrl('ytwd/gw/emr-banner.png')
    this.imgUrl.img1 = getAliyunImg.signatureUrl('ytwd/gw/emr-text.png')
    this.imgUrl.bg1 = getAliyunImg.signatureUrl('ytwd/gw/emr-img1.png')
    this.imgUrl.bg2 = getAliyunImg.signatureUrl('ytwd/gw/emr-img2.png')
    this.imgUrl.bg3 = getAliyunImg.signatureUrl('ytwd/gw/emr-img3.png')
    this.imgUrl.bg4 = getAliyunImg.signatureUrl('ytwd/gw/emr-img4.png')
  },
  data () {
    return {
      imgUrl: {
        banner: '',
        img1: '',
        bg1: '',
        bg2: '',
        bg3: '',
        bg4: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./css/style.scss";
.banner {
  // background-image: url("../../assets/images/emr-banner.png");
  .icon {
    background-image: url("../../assets/images/emr-icon.png");
    background-position: 28px center;
  }
}
.content {
  .en.t3 {
    background-color: #fff;
    top: 0;
  }
  &.bg {
    padding-top: 69px;
    // margin-top: 40px;
    .product h2 {
      position: absolute;
      top: -68px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .product .details {
    margin-bottom: 60px;
    align-items: center;
    .text {
      padding: 75px 30px;
      display: flex;
      height: auto;
      // align-self: flex-start;
      &.bg {
        background-size: 210px 207px;
      }
    }
    .img {
      // height: 535px;
      background: none;
    }
  }
}
.p-list {
  padding: 80px 0 50px;
  ul {
    width: 1300px;
    height: 416px;
    li {
      width: 242px;
      height: 416px;
      border-radius: 10px;
      margin-right: 77px;
      background-repeat: no-repeat;
      background-position: -2px 0;
      box-shadow: 5px 5px 10px rgba($color: #000000, $alpha: 0.14);
      float: left;
      // overflow: hidden;
      em {
        background-repeat: no-repeat;
        background-position: top center;
        display: block;
        width: 100%;
        height: 102px;
        margin-top: 104px;
      }
      h3 {
        margin-top: 60px;
        color: #00dcd4;
        font-size: 24px;
        text-align: center;
      }
      &.p1 {
        background-image: url("../../assets/images/emr-img1.png");
        em {
          background-image: url("../../assets/images/emr-icon1.png");
        }
      }
      &.p2 {
        background-image: url("../../assets/images/emr-img2.png");
        em {
          background-image: url("../../assets/images/emr-icon2.png");
        }
      }
      &.p3 {
        background-image: url("../../assets/images/emr-img3.png");
        em {
          background-image: url("../../assets/images/emr-icon3.png");
        }
      }
      &.p4 {
        background-image: url("../../assets/images/emr-img4.png");
        em {
          background-image: url("../../assets/images/emr-icon4.png");
        }
      }
    }
  }
}
</style>
